
<template>
  <div class="main-layout">

    <img class="icon-clock" :src="state.detail.logo" alt="">
    <img class="icon-success" src="../../assets/success.png" alt="">
    <p class="success-desc">打卡成功</p>
    <p class="success-desc-more">您已在“书香长沙”活动{{state.detail.mainVenueStr}}</p>
    <p style="margin-top: 2px" class="success-desc-more">{{state.detail.venueName }} 打卡成功</p>

    <div class="digital-info">

      <div class="award-info">

        <div class="award">
          <img :src="state.detail.productImage" alt="">
          <p>{{state.detail.productName}}</p>
        </div>
        <div class="award">
<!--          <img class="img-coupon" src="../../assets/home/icon_coupon_award.png" alt="">-->
          <div class="right-coupon">
            <div class="img-coupon">
              <span class="amount">{{state.detail.amount}}</span>
              <span class="unit">元</span>
            </div>
          </div>
          <p>{{state.detail.amount}}元现金图书券</p>
        </div>


      </div>


      <p>您已获得{{state.detail.productName}}1枚及{{state.detail.amount}}元现金图书券1张，点击下方按钮可以查看数字徽章详情，快捷出示优惠券核销码</p>

    </div>

    <div class="bottom-btns">
      <div class="btn" @click="toMine">查看徽章</div>
      <div class="btn" @click="toMine" style="margin-left: 20px">查看图书券</div>
    </div>



  </div>
</template>

<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import {useRoute, useRouter} from "vue-router";;

export default {
  name: "getDigitalSuccess",
  setup() {
    const $router = useRouter();
    const $route = useRoute();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      detail:$route.query
    })





    const copyAddress = ((str) => {
      //新建一个文本框
      let oInput = document.createElement('input');
      //赋值给文本框
      oInput.value = str;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove()
      proxy.$toast.success('复制成功');
    })

    const clickToDigitalListPage = () => {
      $router.push('/digitalListPage')
    }

    const toMine = () => {
      $router.replace('/mine')
    }


    return {
      state,
      toMine,
      copyAddress,


    }
  }
}
</script>

<style scoped lang="scss" src="./index.scss">

</style>


